(function (root, factory) {
    var routing = factory();
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], routing.Routing);
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = routing.Routing;
    } else {
        // Browser globals (root is window)
        root.Routing = routing.Routing;
        root.fos = {
            Router: routing.Router
        };
    }
}(this, function () {
    var exports = {};
    "use strict";
exports.__esModule = true;
exports.Routing = exports.Router = void 0;
var Router = /** @class */ (function () {
    function Router(context, routes) {
        this.context_ = context || { base_url: '', prefix: '', host: '', port: '', scheme: '', locale: '' };
        this.setRoutes(routes || {});
    }
    Router.getInstance = function () {
        return exports.Routing;
    };
    Router.setData = function (data) {
        var router = Router.getInstance();
        router.setRoutingData(data);
    };
    Router.prototype.setRoutingData = function (data) {
        this.setBaseUrl(data['base_url']);
        this.setRoutes(data['routes']);
        if (typeof data.prefix !== 'undefined') {
            this.setPrefix(data['prefix']);
        }
        if (typeof data.port !== 'undefined') {
            this.setPort(data['port']);
        }
        if (typeof data.locale !== 'undefined') {
            this.setLocale(data['locale']);
        }
        this.setHost(data['host']);
        if (typeof data.scheme !== 'undefined') {
            this.setScheme(data['scheme']);
        }
    };
    Router.prototype.setRoutes = function (routes) {
        this.routes_ = Object.freeze(routes);
    };
    Router.prototype.getRoutes = function () {
        return this.routes_;
    };
    Router.prototype.setBaseUrl = function (baseUrl) {
        this.context_.base_url = baseUrl;
    };
    Router.prototype.getBaseUrl = function () {
        return this.context_.base_url;
    };
    Router.prototype.setPrefix = function (prefix) {
        this.context_.prefix = prefix;
    };
    Router.prototype.setScheme = function (scheme) {
        this.context_.scheme = scheme;
    };
    Router.prototype.getScheme = function () {
        return this.context_.scheme;
    };
    Router.prototype.setHost = function (host) {
        this.context_.host = host;
    };
    Router.prototype.getHost = function () {
        return this.context_.host;
    };
    Router.prototype.setPort = function (port) {
        this.context_.port = port;
    };
    Router.prototype.getPort = function () {
        return this.context_.port;
    };
    ;
    Router.prototype.setLocale = function (locale) {
        this.context_.locale = locale;
    };
    Router.prototype.getLocale = function () {
        return this.context_.locale;
    };
    ;
    /**
     * Builds query string params added to a URL.
     * Port of jQuery's $.param() function, so credit is due there.
     */
    Router.prototype.buildQueryParams = function (prefix, params, add) {
        var _this = this;
        var name;
        var rbracket = new RegExp(/\[\]$/);
        if (params instanceof Array) {
            params.forEach(function (val, i) {
                if (rbracket.test(prefix)) {
                    add(prefix, val);
                }
                else {
                    _this.buildQueryParams(prefix + '[' + (typeof val === 'object' ? i : '') + ']', val, add);
                }
            });
        }
        else if (typeof params === 'object') {
            for (name in params) {
                this.buildQueryParams(prefix + '[' + name + ']', params[name], add);
            }
        }
        else {
            add(prefix, params);
        }
    };
    /**
     * Returns a raw route object.
     */
    Router.prototype.getRoute = function (name) {
        var prefixedName = this.context_.prefix + name;
        var sf41i18nName = name + '.' + this.context_.locale;
        var prefixedSf41i18nName = this.context_.prefix + name + '.' + this.context_.locale;
        var variants = [prefixedName, sf41i18nName, prefixedSf41i18nName, name];
        for (var i in variants) {
            if (variants[i] in this.routes_) {
                return this.routes_[variants[i]];
            }
        }
        throw new Error('The route "' + name + '" does not exist.');
    };
    /**
     * Generates the URL for a route.
     */
    Router.prototype.generate = function (name, opt_params, absolute) {
        var route = (this.getRoute(name));
        var params = opt_params || {};
        var unusedParams = Object.assign({}, params);
        var url = '';
        var optional = true;
        var host = '';
        var port = (typeof this.getPort() == 'undefined' || this.getPort() === null) ? '' : this.getPort();
        route.tokens.forEach(function (token) {
            if ('text' === token[0] && typeof token[1] === 'string') {
                url = Router.encodePathComponent(token[1]) + url;
                optional = false;
                return;
            }
            if ('variable' === token[0]) {
                if (token.length === 6 && token[5] === true) { // Sixth part of the token array indicates if it should be included in case of defaults
                    optional = false;
                }
                var hasDefault = route.defaults && !Array.isArray(route.defaults) && typeof token[3] === 'string' && (token[3] in route.defaults);
                if (false === optional || !hasDefault || ((typeof token[3] === 'string' && token[3] in params) && !Array.isArray(route.defaults) && params[token[3]] != route.defaults[token[3]])) {
                    var value = void 0;
                    if (typeof token[3] === 'string' && token[3] in params) {
                        value = params[token[3]];
                        delete unusedParams[token[3]];
                    }
                    else if (typeof token[3] === 'string' && hasDefault && !Array.isArray(route.defaults)) {
                        value = route.defaults[token[3]];
                    }
                    else if (optional) {
                        return;
                    }
                    else {
                        throw new Error('The route "' + name + '" requires the parameter "' + token[3] + '".');
                    }
                    var empty = true === value || false === value || '' === value;
                    if (!empty || !optional) {
                        var encodedValue = Router.encodePathComponent(value);
                        if ('null' === encodedValue && null === value) {
                            encodedValue = '';
                        }
                        url = token[1] + encodedValue + url;
                    }
                    optional = false;
                }
                else if (hasDefault && (typeof token[3] === 'string' && token[3] in unusedParams)) {
                    delete unusedParams[token[3]];
                }
                return;
            }
            throw new Error('The token type "' + token[0] + '" is not supported.');
        });
        if (url === '') {
            url = '/';
        }
        route.hosttokens.forEach(function (token) {
            var value;
            if ('text' === token[0]) {
                host = token[1] + host;
                return;
            }
            if ('variable' === token[0]) {
                if (token[3] in params) {
                    value = params[token[3]];
                    delete unusedParams[token[3]];
                }
                else if (route.defaults && !Array.isArray(route.defaults) && (token[3] in route.defaults)) {
                    value = route.defaults[token[3]];
                }
                host = token[1] + value + host;
            }
        });
        url = this.context_.base_url + url;
        if (route.requirements && ('_scheme' in route.requirements) && this.getScheme() != route.requirements['_scheme']) {
            var currentHost = host || this.getHost();
            url = route.requirements['_scheme'] + '://' + currentHost + (currentHost.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        else if ('undefined' !== typeof route.schemes && 'undefined' !== typeof route.schemes[0] && this.getScheme() !== route.schemes[0]) {
            var currentHost = host || this.getHost();
            url = route.schemes[0] + '://' + currentHost + (currentHost.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        else if (host && this.getHost() !== host + (host.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port)) {
            url = this.getScheme() + '://' + host + (host.indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        else if (absolute === true) {
            url = this.getScheme() + '://' + this.getHost() + (this.getHost().indexOf(':' + port) > -1 || '' === port ? '' : ':' + port) + url;
        }
        if (Object.keys(unusedParams).length > 0) {
            var queryParams_1 = [];
            var add = function (key, value) {
                // if value is a function then call it and assign it's return value as value
                value = (typeof value === 'function') ? value() : value;
                // change null to empty string
                value = (value === null) ? '' : value;
                queryParams_1.push(Router.encodeQueryComponent(key) + '=' + Router.encodeQueryComponent(value));
            };
            for (var prefix in unusedParams) {
                if (unusedParams.hasOwnProperty(prefix)) {
                    this.buildQueryParams(prefix, unusedParams[prefix], add);
                }
            }
            url = url + '?' + queryParams_1.join('&');
        }
        return url;
    };
    /**
     * Returns the given string encoded to mimic Symfony URL generator.
     */
    Router.customEncodeURIComponent = function (value) {
        return encodeURIComponent(value)
            .replace(/%2F/g, '/')
            .replace(/%40/g, '@')
            .replace(/%3A/g, ':')
            .replace(/%21/g, '!')
            .replace(/%3B/g, ';')
            .replace(/%2C/g, ',')
            .replace(/%2A/g, '*')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/'/g, '%27');
    };
    /**
     * Returns the given path properly encoded to mimic Symfony URL generator.
     */
    Router.encodePathComponent = function (value) {
        return Router.customEncodeURIComponent(value)
            .replace(/%3D/g, '=')
            .replace(/%2B/g, '+')
            .replace(/%21/g, '!')
            .replace(/%7C/g, '|');
    };
    /**
     * Returns the given query parameter or value properly encoded to mimic Symfony URL generator.
     */
    Router.encodeQueryComponent = function (value) {
        return Router.customEncodeURIComponent(value)
            .replace(/%3F/g, '?');
    };
    return Router;
}());
exports.Router = Router;
exports.Routing = new Router();
exports["default"] = exports.Routing;


    return { Router: exports.Router, Routing: exports.Routing };
}));;


fos.Router.setData({"base_url":"","routes":{"partner_login":{"tokens":[["text","\/partner-login"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["POST"],"schemes":[]},"partner_forgot_password":{"tokens":[["text","\/partner-forgot-password"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["POST"],"schemes":[]},"get_uptime_hyperspin":{"tokens":[["text","\/get-uptime-hyperspin\/"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":[],"schemes":[]},"get_uptime_everest":{"tokens":[["text","\/get-uptime-everest\/"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":[],"schemes":[]},"get_rate":{"tokens":[["text","\/get-rate"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"get_rates":{"tokens":[["text","\/international-rates.csv"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]},"support_search":{"tokens":[["text","\/support\/search"]],"defaults":[],"requirements":[],"hosttokens":[],"methods":["GET"],"schemes":[]}},"prefix":"","host":"localhost","port":"","scheme":"https","locale":""});;


var userCountry = 'AU';

$(document).ready(function () {
    $(window).load( function () {
        setTopBlocksSize();
        menuBG();
    });

    $(window).scroll(function() {
        menuBG();
    });

    function menuBG() {
        var scrollTop = $(document).scrollTop();

        if (scrollTop > 0) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    }

    $(window).resize(function () {
        scrollbarWidth = getScrollbarWidth();
        currentWidth = $(window).width() + scrollbarWidth;

        if (changedSize(previousWidth, currentWidth)) {
            var event = new Event("windowResizeBreakpointChanged");
            window.dispatchEvent(event);

        } else if (previousWidth <= screenXsMaxWidth && currentWidth <= screenXsMaxWidth) {
            var event = new Event("windowResizeInsideXS");
            window.dispatchEvent(event);
        }

        previousWidth = currentWidth;

        setTopBlocksSize();
    });

    /* Open menu */
    $('.mobile-btn').on('click', function () {
        var btnText = $(this).find('span');
        if (btnText.text() === 'Menu') {
            btnText.text('Close');
        } else {
            btnText.text('Menu');
        }
        $(this).toggleClass('active');
        $('.main-nav').toggleClass('active');
        $('body').toggleClass('is-menu-open');
    });

    $(".apply-btn").click(function () {
        $("#join_popup").modal('show');
        //$(".main-header").css('width', $(".top-bar").css('width'));
        return false;
    });

    // login form validation
    // v3
    $('form.login-form .submit-btn').click(function () {
        var errorsCount = 0;
        var $emailField = $('#login-name-field');
        var $passwordField = $('#login-password-field');
        var $country = $('#login-country-field').selectpicker('val');

        // inputs
        $('.login-form').find('.required').each(function () {
            if ($(this).val() === '') {
                $(this).next('.error_msg').addClass('visible');
                errorsCount++;
            } else {
                $(this).next('.error_msg').removeClass('visible');
            }
        });

        //select country
        if (!$country) {
            $('.login-form .country_error_msg').addClass('visible');
            errorsCount++;
        } else {
            $('.login-form .country_error_msg').removeClass('visible');
        }

        if (!errorsCount) {
            // reCaptcha block
            if (appConfig.isUseRecaptcha == '1') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(appConfig.grecaptchaKey, { action: 'partner_login' }).then(function (token) {
                        partner_login(token);
                    });
                });
            } else {
                partner_login();
            }
        }

        function partner_login(token) {
            $.ajax({
                type: 'POST',
                url: Routing.generate('partner_login'),
                dataType: 'json',
                data: {
                    login_name: $emailField.val(),
                    password: $passwordField.val(),
                    country: $country,
                    recaptchaResponse: token
                },
                beforeSend: function () {
                    $('.login-form .submit-btn').attr('disabled', 'disabled');
                    $('#form_error_msg').html('');
                    $('#form_error_msg').removeClass('visible');
                },
                success: function (data) {
                    if (data['success']) {
                        // redirect
                        document.location.href = data.response;
                    }
                    else {
                        $('#form_error_msg').addClass('visible');
                        $.each(data['errors'], function (index, value) {
                            $('#form_error_msg').append(value + '<br>');
                        });
                    }
                },
                complete: function () {
                    $('.login-form .submit-btn').removeAttr('disabled');
                }
            });
        }
        return false;
    });

    $("#login_popup .join_reseller_programm").click(function () {
        $('body').one('hidden.bs.modal', '#login_popup', function () {
            $('#join_popup').modal('show');
        });
        //$('#join_popup').css('transition', 0);
        $(this).closest('.modal').modal('hide');
        return false;
    });

    $(".forgot_password_link").click(function () {
        $(".forgot_password").slideDown("slow");
        $('#login_popup').animate({
            scrollTop: $(".forgot_password .my_text").position().top
        }, 2000);

        return false;
    });
    $(".forgot_password .cancel").click(function () {
        $(".forgot_password").slideUp("slow");
        return false;
    });

    // forgot password form
    // v3
    $('.forgot_password form .send').click(function () {
        var errorsCount = 0;
        var $emailField = $('.forgot_password [name=email]');
        var $country = $('.forgot_password .country').selectpicker('val');

        // input: email
        $('.forgot_password').find('.required').each(function () {
            if ($(this).val() === '') {
                $(this).next('.error_msg').addClass('visible');
                errorsCount++;
            } else {
                $(this).next('.error_msg').removeClass('visible');
            }
        });
        //select country
        if (!$country) {
            $('.forgot_password .country_error_msg').addClass('visible');
            errorsCount++;
        } else {
            $('.forgot_password .country_error_msg').removeClass('visible');
        }

        // email validation
        if (!errorsCount) {
            var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.(([a-z0-9-])+\.)?[a-z]{2,10}$/i;
            if (pattern.test($emailField.val())) {
                $emailField.next().next('.error_msg_wrong').removeClass('visible');
            }
            else {
                $emailField.next().next('.error_msg_wrong').addClass('visible');
                errorsCount++;
            }
        }

        if (!errorsCount) {
            // reCaptcha block
            if (appConfig.isUseRecaptcha == '1') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(appConfig.grecaptchaKey, { action: 'partner_forgot_password' }).then(function (token) {
                        partner_forgot_password(token);
                    });
                });
            } else {
                partner_forgot_password();
            }
        }

        function partner_forgot_password(token) {
            $.ajax({
                type: 'POST',
                url: Routing.generate('partner_forgot_password'),
                dataType: 'json',
                data: {
                    email: $emailField.val(),
                    country: $country,
                    recaptchaResponse: token
                },
                beforeSend: function () {
                    $('.forgot_password .submit-btn').attr('disabled', 'disabled');
                    $('#forgot_password_error_msg').html('');
                    $('#forgot_password_error_msg').removeClass('visible');
                },
                success: function (data) {
                    if (data['success']) {
                        $('#forgot_password_error_msg').html('Email has been sent.');
                        $('#forgot_password_error_msg').addClass('visible');
                    } else {
                        $('#forgot_password_error_msg').addClass('visible');
                        $.each(data['errors'], function (index, value) {
                            $('#forgot_password_error_msg').append(value + '<br>');
                        });
                    }
                },
                complete: function () {
                    $('.forgot_password .submit-btn').removeAttr('disabled');
                }
            });
        }
        return false;
    });

    // init all bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip();

    //reseller login window
    $(".login-btn").click(function () {
        $("#login_popup").modal('show');
        //$(".main-header").css('width', $(".top-bar").css('width'));
        return false;
    });

    // geo location
    getCountry();
    function getCountry() {
        $.get("https://ipinfo.io", function (response) {
            //console.log('ipinfo', response.country);
            setCountry(response.country);
        }, "jsonp");
    }

    function checkCountry(country) {
        if ($.inArray(country, ['AU', 'IE', 'NZ', 'GB', 'US']) < 0) {
            country = 'AU';
        }
        return country;
    }

    function setCountry(country) {
        userCountry = checkCountry(country); 

        localStorage.setItem('user_country', userCountry);
        $("#login_popup select.country").selectpicker('val', userCountry);
        if ($("#get_in_touch-country").length) {
            $("#get_in_touch-country").selectpicker('val', userCountry);
            reloadCountryBlocks();
        }
        if ($(".support-page").length) {
            reloadPhoneBlocks();
        }
    }

    $('#login_popup select.country').on('change', function (e) {
        //console.log('#login_popup select.country');
        setCountry(this.value);
    });

    $('#get_in_touch-country').on('change', function (e) {
        //console.log('#get_in_touch-country');
        setCountry(this.value);
        reloadCountryBlocks();
    });

    // initSmoothScrolling
    $('a[href*="#"]:not([href="#"]):not([data-toggle]):not(".no-scroll")').click(function () {
        if ((location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')) && (location.hostname === this.hostname)) {
            var target = $(this.hash);
            if (target.length) {
                scrollToElement(target);
                return false;
            }
        }
    });
    function scrollToElement(target) {
        var $topMenuHeight = parseInt($('.top-bar').css('height')) + parseInt($('.main-header').css('height'));

        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - $topMenuHeight
            }, 1000);

            //window.location.hash = '';
            var scrollV, scrollH, loc = window.location;
            if ("pushState" in history)
                history.pushState("", document.title, loc.pathname + loc.search);
            else {
                // Prevent scrolling by storing the page's current scroll offset
                scrollV = document.body.scrollTop;
                scrollH = document.body.scrollLeft;
                loc.hash = "";
                // Restore the scroll offset, should be flicker free
                document.body.scrollTop = scrollV;
                document.body.scrollLeft = scrollH;
            }
        }
    }

    // System uptime page
    $.ajax({
        url: Routing.generate('get_uptime_hyperspin'),
        type: 'json',
        method: 'GET',
        success: function (response) {
            $('#uptime_table_au').html(response.replace('_uptime_title_', 'Equinix BGP Link'));
            $('#uptime-table-box_au').show();
            $('#uptime_table_nz').html(response.replace('_uptime_title_', 'Equinix/PIPE BGP Link'));
            $('#uptime-table-box_nz').show();
        }
    });
    $.ajax({
        url: Routing.generate('get_uptime_everest'),
        type: 'json',
        method: 'GET',
        success: function (response) {
            $('#uptime_table_uk').html(response.replace('_uptime_title_', 'Pulsant DC'));
            $('#uptime-table-box_uk').show();
            $('#uptime_table_ie').html(response.replace('_uptime_title_', 'Pulsant DC'));
            $('#uptime-table-box_ie').show();
        }
    });

    $.ajax({
        url: Routing.generate('get_uptime_everest'),
        type: 'json',
        method: 'GET',
        success: function (response) {
            $('#uptime_table_us').html(response.replace('_uptime_title_', 'CoreSite DC'));
            $('#uptime-table-box_us').show();
        }
    });

    $(".sitemap .login").click(function () {
        $('.main-header__login-btn:first').click();
        window.location.hash = '';
    });
    $(".sitemap .join_reseller_programm").click(function () {
        $('html, body').animate({
            scrollTop: $(".join").offset().top
        }, 2000);
        return false;
    });

    if (window.location.hash) {
        var $el = '';

        switch (window.location.hash) {
            case '#login':
                $el = $('.login-btn:first');
                break;
            case '#apply':
                $el = $('.apply-btn:first');
                break;
            case '#porting':
                $el = $('#number_porting_btn');
                break;
            case '#int-numbers':
                $el = $('#inter_phone_btn');
                break;
            default:
                var hash = window.location.hash.substr(1);
                var target = $('[id=' + hash + ']');
                if (target.length) {
                    scrollToElement(target);
                    return false;
                }
        }

        if ($el && $el.length) {
            $el.click();
            window.location.hash = '';
        }
    }

    $(".play-video-link").on("click", function(e) {
        e.preventDefault();
        var t = $("body"),
            o = $(e.target);
        o.hasClass("play-video-link") || (o = o.parents(".play-video-link"));
        var a = o.attr("data-video-url");
        if (a = a.replace("watch?v=", "v/")) {
            var n = a.split("/").pop();
            if (n) {
                var r = '<div id="dialog-modal-help-video" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"><div class="modal-dialog modal-lg"><div class="modal-content"><div class="modal-header"><a href="#" class="close-link" data-dismiss="modal" aria-hidden="true"><span class="close-label">close</span><span class="close-icon icon-cancel-circled2"></span></a></div><div class="modal-body"><div class="video-wrapper-block"><iframe width="560" height="315" src="https://www.youtube.com/embed/' + n + '" frameborder="0" allowfullscreen></iframe></div></div></div></div></div>';
                t.append($(r)),
                    $("#dialog-modal-help-video").modal({
                        backdrop: !0
                    }),
                    t.one("hidden.bs.modal", "#dialog-modal-help-video", function() {
                        $("#dialog-modal-help-video").remove(),
                            $(this).removeData("bs.modal")
                    })
            }
        }
    });
    $(".youtube_video").each(function() {
        // Based on the YouTube ID, we can easily find the thumbnail image
        $(this).css('background-image', 'url(https://i.ytimg.com/vi/' + this.id + '/sddefault.jpg)');

        // Overlay the Play icon to make it look like a video player
        $(this).append($('<div/>', {'class': 'icon-play'}));

        $(document).delegate('#'+this.id, 'click', function() {
            // Create an iFrame with autoplay set to true
            var iframe_url = "https://www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
            if ($(this).data('params')) iframe_url+='&'+$(this).data('params');

            // The height and width of the iFrame should be the same as parent
            var iframe = $('<iframe></iframe>', {
                'src': iframe_url,
                'width': $(this).width(),
                'height': $(this).height(),
                'frameborder': '0',
                'allow': 'autoplay;',
                'allowfullscreen': ''
            });
            //console.log($(this).width(), $(this).height());

            // Replace the YouTube thumbnail with YouTube HTML5 Player
            $(this).replaceWith(iframe);
        });
    });


    /* Particles js */
    if ($('#particles-js').length) {
        particlesJS.load('particles-js', '/js/particles-config.json', function () {
            //console.log('callback - particles.js config loaded');
        });
    }
});


if (appConfig.isUseRecaptcha !== '1') {
    activateSubmitButton('#login_popup .submit-btn');

} else {
    var recaptchaInterval = setInterval(function () {
        if (typeof grecaptcha !== 'undefined' && grecaptcha.render) {
            clearInterval(recaptchaInterval);

            activateSubmitButton('#login_popup .submit-btn');
        }
    }, 1000);
}

$(document).on('click', '.get-rate-form button, .get-rate-form-free-calls button', function (event) {
  event.preventDefault();
  var currentCountry = document.location.pathname.match(/^\/([a-zA-Z]+)/i)[1]

  var $button = $(event.target);
  var $form = $button.parents('form');

  var $numberField = $form.find('input');
  var $rateChecker = $form.find('#get-rate-phone-input-free-calls').length === 1 ? 2 : 1;
  var $resultBlock = $form.parents('.get-rate-column').find('.get-rate-result-block');

  var isFormValid = true;
  $form.find('.form-group').removeClass('has-error');
  //$form.find('.form-group .help-block').hide();

  if (!$numberField.val()) {
      $numberField.parents('.form-group').addClass('has-error');
      $resultBlock
          .text('Number is a required field')
          .show();
      isFormValid = false;
  }

  if (isFormValid) {
      // reCaptcha block
      if (appConfig.isUseRecaptcha === '1') {
          grecaptcha.ready(function () {
              grecaptcha.execute(appConfig.grecaptchaKey, { action: 'get_rate' }).then(function (token) {
                  get_rate(token);
              });
          });
      } else {
          get_rate();
      }
  }

  function get_rate(token) {
      $button.prop('disabled', true);

      $.ajax({
          url: Routing.generate('get_rate'),
          type: 'json',
          method: 'GET',
          data: {
              number: $numberField.val(),
              rateChecker: $rateChecker,
              recaptchaResponse: token,
              country: currentCountry
          },
          success: function(response) {
              $resultBlock
                  .show()
                  .html(response);
              $form.parents('.get-rate-column').find('.get-rate-example').css('display', 'none');
          },
          complete: function() {
              $button.prop('disabled', false);
          }
      });
  }
});

function activateSubmitButton(button) {
    $(button).prop('disabled', false);
    //$(button).removeAttr('disabled');
}

/* status, top menu size*/
function setTopBlocksSize() {
    $('.top-bar').css('width', 'calc(100vw - ' + scrollbarWidth + 'px)');
    $('.main-header').css('width', 'calc(100vw - ' + scrollbarWidth + 'px)');
    $('.main-header').css('max-width', 'auto');
    $('body').css('width', 'calc(100vw - ' + scrollbarWidth + 'px)');
}

function hideFormHeader()
{
    $('#join_popup h3').hide();
    $('#join_popup .descr').hide();
}
